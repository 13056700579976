import Api from "@/Apis/Api";


export default {
    getCookie(){
        return Api.get('/sanctum/csrf-cookie', {
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            },
            withCredentials: true
        })
    }
}
