import axios from "axios";
let Api = axios.create();

Api.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

Api.defaults.withCredentials = true;

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    Api.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

export default Api;
